<template>
  <div>
    <el-select
      class="w-full"
      v-model="customerSelect"
      filterable
      clearable
      remote
      reserve-keyword
      :remote-method="searchCustomer"
      :loading="loading"
      placeholder="Rechercher un fournisseur"
      @change="handleCustomer"
    >
      <i slot="prefix" class="el-input__icon el-icon-search"></i>
      <el-option
        v-for="item in customers"
        :key="item.contact_id"
        :label="`${item.contact_no} - ${item.contact_name}`"
        :value="item.contact_id"
      >
        <span style="float: left">{{ item.contact_name }}</span>
        <span style="float: right; color: #8492a6; font-size: 13px">
          {{ item.street }} / {{ item.phone }}
        </span>
      </el-option>
    </el-select>
  </div>
</template>

<script>
import { searchSupplier } from "@/api/contact";
export default {
  name: "SEARCH-CLIENT",
  data() {
    return {
      customers: [],
      customerSelect: "",
      loading: false,
    };
  },
  methods: {
    async searchCustomer(query) {
      // console.log(query);
      if (query.length !== "") {
        this.loadingCustomer = true;
        await searchSupplier({ search: query })
          .then((res) => {
            setTimeout(() => {
              this.customers = res.data;
              this.loadingCustomer = false;
            }, 200);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    handleCustomer(id) {
      this.$emit("supplier", id);
    },
  },
};
</script>

<style lang="scss" scoped></style>
