<template>
  <div class="flex flex-col font-Inter px-4 mb-6">
    <div class="flex flex-col mt-10 shadow bg-white border p-6">
      <div class="flex flex-row mb-4 items-center">
        <router-link class="mr-2" to="/purchases/bills/create">
          <el-button type="primary" icon="el-icon-plus">Créer </el-button>
        </router-link>
        <inputSupplier class="mr-2 flex-1" @supplier="handleSupplier" />
        <el-date-picker
          class="mr-2 w-1/2"
          v-model="listQuery.start"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="Date debut"
        >
        </el-date-picker>
        <el-date-picker
          class="mr-2 w-24"
          v-model="listQuery.end"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="Date fin"
        >
        </el-date-picker>
        <el-button @click="fetchBill()">Rechercher</el-button>
      </div>

      <el-tabs v-model="activeName" @tab-click="handleTab">
        <el-tab-pane label="Toutes" name="ALL"></el-tab-pane>
        <el-tab-pane
          :label="`En cours (${statist.total_item}) `"
          name="OPEND"
        ></el-tab-pane>
        <el-tab-pane label="Payées" name="PAID"></el-tab-pane>
        <el-tab-pane label="Annulées" name="CANCLED"></el-tab-pane>
      </el-tabs>
      <div v-if="activeName === 'OPEND'" class="mb-2 text-blue font-semibold">
        Montant impayées :
        {{ statist.total | moneyFilter }} {{user.currency_code}}
      </div>
      <div class="customer-white">
          <div class="flex justify-end mb-2">
              <el-dropdown class="mr-2" @command="handleExport">
                  <el-button type="text" :loading="exportLoading">
                      Exporter <i class="el-icon-arrow-down el-icon--right"></i>
                  </el-button>
                  <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item command="PDF">PDF</el-dropdown-item>
                      <el-dropdown-item command="EXCEL">EXCEL</el-dropdown-item>
                  </el-dropdown-menu>
              </el-dropdown>
          </div>
        <skeleton-list :row="8" v-if="listLoading" />
        <el-table :data="items" v-else style="width: 100%">
          <el-table-column
            prop="purchaseorder_date"
            label="DATE création"
            width="180"
          >
            <template slot-scope="{ row }">
              <span>{{ row.created_at }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="purchaseorder_no"
            label="N°FACTURE"
            width="180"
          >
            <template slot-scope="{ row }">
              <router-link :to="`/purchases/bills/` + row.bill_id">
                <span class="text-blue-500">{{ row.bill_no }}</span>
              </router-link>
            </template>
          </el-table-column>

          <el-table-column prop="" label="ÉCHEANCE" width="150">
            <template slot-scope="{ row }">
              <span>{{ row.bill_due_date }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="status" label="STATUT" width="160">
            <template slot-scope="{ row }">
              <Status :name="row.status" />
            </template>
          </el-table-column>

          <el-table-column min-width="200" prop="vendor_first_name" label="FOURNISSEUR">
            <template slot-scope="{ row }">
              <div class="flex flex-col">
                <span class="uppercase">{{ row.contact_organization }}</span>
                <span class="text-gray-500 text-xs"
                  >{{ row.contact_phone }}/{{ row.contact_street }}
                </span>
              </div>
            </template>
          </el-table-column>

          <el-table-column width="140" label="TOTAL TTC" align="right">
            <template slot-scope="{ row }">
              <span class="">{{ row.total | moneyFilter }} </span>
            </template>
          </el-table-column>
          <el-table-column label="AVANCE" width="140" align="right">
            <template slot-scope="{ row }">
              <span class="">{{ row.avance | moneyFilter }} </span>
            </template>
          </el-table-column>
          <el-table-column width="170" label=" À REGLER" align="right">
            <template slot-scope="{ row }">
              <span v-if="row.total !== row.avance"
                >{{ (row.total - row.avance) | moneyFilter }} {{user.currency_code}}
              </span>
              <span v-else> - </span>
            </template>
          </el-table-column>
          <el-table-column width="150" label="Actions" align="right">
            <template slot-scope="{ row }">
              <el-button
                v-if="row.status === 'OPEND'"
                size="mini"
                type="primary"
                icon="el-icon-plus"
                @click="handlePaymentBill(row)"
                circle
              >
              </el-button>
              <!--   <el-button
                size="mini"
                type="danger"
                icon="el-icon-delete"
                @click="handleCanceledBill(row)"
                circle
              >
              </el-button> -->
              <!--     <cancel-bill
                v-if="row.status === 'OPEND'"
                :circle="true"
                type="BILL"
                :id="row.bill_id"
                :billNo="row.bill_no"
                @success="handleSuccess"
              /> -->
              <el-dropdown
                style="margin-left: 10px"
                @command="handleCommand"
                trigger="click"
              >
                <el-button size="mini" icon="el-icon-menu" circle></el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item :command="{ get: row }">
                    <i class="el-icon-tickets"></i>
                    Afficher
                  </el-dropdown-item>
                  <el-dropdown-item
                    v-if="row.status === 'DRAFT'"
                    :command="{ update: row }"
                  >
                    <i class="el-icon-edit"></i>
                    Modifier
                  </el-dropdown-item>
                  <!--          <el-dropdown-item :command="{ copy: row }">
                    <i class="el-icon-document-copy"></i>
                    Dupliquer
                  </el-dropdown-item> -->
                  <el-dropdown-item
                    v-if="row.status === 'OPEND' || row.status === 'DRAFT'"
                    :command="{ cancel: row }"
                  >
                    <i class="el-icon-delete"></i>
                    Annuler
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="flex justify-end">
        <pagination
          v-show="listQuery.total > 0"
          :total="listQuery.total"
          :page.sync="listQuery.page"
          :limit.sync="listQuery.size"
          @pagination="fetchBill()"
        />
      </div>
    </div>
    <!-- List Payment en Bl -->
    <el-dialog
      title="Reglèment facture d'achat"
      :visible.sync="dialogPaymentInvoice"
      width="35%"
      top="4vh"
      :close-on-click-modal="false"
    >
      <el-form
        :model="paymentBill"
        :rules="rules"
        ref="paymentBillForm"
        :label-position="labelPosition"
      >
        <div class="flex">
          <el-form-item class="w-1/2 mr-2" prop="payment_no" label="Numéro ">
            <el-input
              class="w-full"
              v-model="paymentBill.payment_no"
              :readonly="true"
            >
            </el-input>
          </el-form-item>

          <el-form-item class="w-1/2" prop="payment_date" label="Date">
            <el-date-picker
              class="w-full"
              v-model="paymentBill.payment_date"
              type="date"
              placeholder="jj-mm-aaaa"
              format="dd MMMM yyyy"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
        </div>
        <div class="flex">
          <el-form-item
            prop="account_number"
            class="w-1/2 mr-2"
            label="Compte de reception"
          >
            <el-select
              v-model="paymentBill.account_number"
              class="w-full"
              @change="selectAccount"
            >
              <el-option
                v-for="item in accounts"
                :key="item.account_id"
                :label="`${item.account_name}`"
                :value="item.numero"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="amount" p class="w-1/2" label="Montant">
            <el-input-number
              class="w-full"
              :controls="false"
              v-model="paymentBill.amount"
              :min="0"
              :max="billToPay.amountToPay"
            ></el-input-number>
          </el-form-item>
        </div>
        <el-form-item label="Reference">
          <el-input
            placeholder="N° du reçu inscrit "
            v-model="paymentBill.reference"
          />
        </el-form-item>
        <el-form-item label="Note">
          <el-input
            type="textarea"
            :autosize="{ minRows: 3, maxRows: 3 }"
            placeholder="Entrez quelque chose"
            v-model="paymentBill.note"
          />
        </el-form-item>
      </el-form>

      <div slot="footer" class="border-t pt-2 dialog-footer">
        <el-button @click="dialogVisible = false">Annuler</el-button>
        <el-button
          type="primary"
          :loading="paymentLoading"
          :disabled="paymentLoading"
          @click="createPaymentBill('paymentBillForm')"
          >Enregistrer</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getAllBill,
  fetchStats,
  cancelBill,
  copyBill,
  generatePaymentNo,
  createPaymentSingleBill,
    exportBillsExcel,
    exportBillsPDF
} from "@/api/purchase";
import { fetchAccounts } from "@/api/account";
import Pagination from "@/components/Pagination";
import { toThousandFilter } from "@/Filters";
import { parseDate, formatDate } from "@/utils";
import inputSupplier from "@/components/searchSupplier";
import Status from "@/components/Status";
import SkeletonList from "@/components/Skeleton/list";
import {mapGetters} from "vuex";


//import CancelBill from "../cancelBill.vue";
export default {
  name: "Bill",
  components: { Pagination, inputSupplier, Status, SkeletonList },
  filters: {
    filterTime: parseDate,
    moneyFilter: toThousandFilter,
  },
  data() {
    return {
      orders: [],
      labelPosition: "top",
      listLoading: false,
      dialogPaymentInvoice: false,
      paymentLoading: false,
      loadingFull: false,
      exportLoading: false,
      statist: {
        total: 600000,
        total_item: 13,
      },
      items: [],
      accounts: [],
      billToPay: {
        supplier_id: "",
        bill_id: "",
        bill_no: "",
        amountToPay: 0,
      },
      paymentBill: {
        supplier_id: "",
        note: "",
        payment_date: formatDate(new Date()),
        payment_no: "",
        account_number: "",
        account_name: "",
        amount: 0,
        reference: "",
        items: [],
      },
      activeName: "ALL",
      type: "BILL",
      listQuery: {
        total: 0,
        page: 1,
        size: 20,
        sort: "desc",
        sortDir: "createdAt",
        type: "BILL",
        start: undefined,
        end: undefined,
        billNo: undefined,
        contactId: undefined,
        status: undefined,
      },
      rules: {
        payment_date: [
          {
            required: true,
            message: "La date est obligatoire",
            trigger: "change",
          },
        ],
        payment_no: [
          {
            required: true,
            message: "La numéro paiement est obligatoire",
            trigger: "bluer",
          },
        ],
        amount: [
          {
            required: true,
            message: "Le montant est obligatoire",
            trigger: "blur",
          },
        ],
        account_number: [
          {
            required: true,
            message: "Le compte est obligatoire",
            trigger: "change",
          },
        ],
      },
    };
  },
  mounted() {
    this.fetchBill();
    this.fetchStatOpen();
    this.getAccounts();
  },
    computed :{
        ...mapGetters(["user"]),
    },
  methods: {
    async fetchBill() {
      this.listLoading = true;

      if (this.listQuery.contact === "") {
        this.listQuery.contact = undefined;
      }

      await getAllBill(this.listQuery)
        .then((res) => {
          setTimeout(() => {
            this.items = res.data.items;
            this.listQuery.total = parseInt(res.data.total_items);
            this.listQuery.page = parseInt(res.data.current_page) + 1;
            this.listLoading = false;
          }, 1.5 * 1000);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async fetchStatOpen() {
      await fetchStats(this.type)
        .then((res) => {
          this.statist.total = res.data.total_amount;
          this.statist.total_item = res.data.total_item;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleTab(obj) {
      const name = obj.name;
      switch (name) {
        case "OPEND":
          this.listQuery.status = "OPEND";
          break;

        case "CLOSED":
          this.listQuery.status = "CLOSED";
          break;
        case "CANCELED":
          this.listQuery.status = "OPEND";
          break;
        case "PAID":
          this.listQuery.status = "PAID";
          break;
        default:
          this.listQuery.status = undefined;
      }
      this.fetchBill();
    },

    handleSupplier(id) {
      this.listQuery.contactId = id;
      this.fetchBill();
    },
    selectAccount(id) {
      const account = this.accounts.find((el) => el.numero === id);

      this.paymentBill.account_name = account.account_name;
    },
    async nextPaymentNo() {
      await generatePaymentNo()
        .then((res) => {
          this.paymentBill.payment_no = res.data.numero;
        })
        .catch((err) => {
          this.$message({
            message: err.data.response,
            type: "danger",
          });
        });
    },
    async getAccounts() {
      await fetchAccounts()
        .then((res) => {
          this.accounts = res.data;
        })
        .catch((err) => {
          this.$message({
            message: err.data.response,
            type: "danger",
          });
        });
    },
    createPaymentBill(paymentBillForm) {
      this.$refs[paymentBillForm].validate((valid) => {
        if (valid) {
          if (this.paymentBill.amount > this.billToPay.amountToPay) {
            this.$message({
              showClose: false,
              message: "Les montant restant à payer est different total saisi",
              type: "error",
            });
          } else {
            this.paymentLoading = true;

            this.paymentBill.items.push({
              bill_id: this.billToPay.bill_id,
              amount: this.paymentBill.amount,
            });

            createPaymentSingleBill(this.paymentBill)
              .then(() => {
                setTimeout(() => {
                  let billItem = this.items.find(
                    (el) => el.bill_id === this.billToPay.bill_id
                  );
                  billItem.avance += this.paymentBill.amount;

                  if (this.billToPay.amountToPay === this.paymentBill.amount) {
                    billItem.status = "PAID";
                  }
                  this.paymentLoading = false;
                  this.dialogPaymentInvoice = false;
                }, 1.5 * 1000);
              })
              .catch((err) => {
                this.paymentLoading = false;
                if (err.data.status === 400) {
                  this.$message({
                    message:
                      "Veuillez renseigner les informations obligatoire et les articles",
                    type: "warning",
                  });
                } else if (err.data.status === 490) {
                  this.$message({
                    message: err.data.message,
                    type: "warning",
                  });
                } else {
                  this.$message({
                    message: "Erreur serveur",
                    type: "danger",
                  });
                }
              });
          }
        }
      });
    },
    resetPaymentBill() {
      this.paymentBill = {
        supplier_id: "",
        note: "",
        payment_date: formatDate(new Date()),
        payment_no: "",
        amount: 0,
        reference: "",
        account_number: "",
        account_name: "",
        items: [],
      };
    },
    handlePaymentBill(row) {
      console.log(row);
      this.resetPaymentBill();
      this.nextPaymentNo();
      this.billToPay.bill_no = row.bill_no;
      this.billToPay.supplier_id = row.contact_id;
      this.billToPay.bill_id = row.bill_id;
      this.billToPay.amountToPay = row.total - row.avance;
      this.paymentBill.supplier_id = row.contact_id;

      const accountDefault = this.accounts.find(
        (el) => el.has_primary === true
      );
      this.paymentBill.account_number = accountDefault.numero;
      this.paymentBill.account_name = accountDefault.account_name;
      this.paymentBill.amount = this.billToPay.amountToPay;
      this.$nextTick(() => {
        this.$refs["paymentBillForm"].clearValidate();
      });
      this.dialogPaymentInvoice = true;
    },
    handleCanceledBill(e) {
      const h = this.$createElement;
      this.$msgbox({
        title: "Facture N° " + e.bill_no,
        message: h("div", null, [
          h("span", null, " Voulez vous vraiment annuler la facture d'achat ?"),
        ]),
        showCancelButton: true,
        showClose: false,
        confirmButtonText: "Confirmer",
        cancelButtonText: "Annuler",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "encours...";
            cancelBill(e.bill_id, this.listQuery.type)
              .then(() => {
                setTimeout(() => {
                  setTimeout(() => {
                    instance.confirmButtonLoading = false;
                  }, 300);
                  let billItem = this.items.find(
                    (el) => el.bill_id === e.bill_id
                  );
                  billItem.status = "CANCELED";
                  this.fetchStatOpen();
                  this.$message({
                    type: "success",
                    message: "La facture d'achat a été annulée",
                  });
                  done();
                }, 1.5 * 1000);
              })
              .catch((err) => {
                setTimeout(() => {
                  this.$message({
                    message: err.data.message,
                    type: "error",
                  });
                  instance.confirmButtonLoading = false;
                  instance.confirmButtonText = "Confirmer";
                }, 1.5 * 1000);
              });
          } else {
            done();
          }
        },
      }).then(() => {});
    },

    handleCopyill(e) {
      const h = this.$createElement;
      this.$msgbox({
        title: "Facture N° " + e.bill_no,
        message: h("div", null, [
          h(
            "span",
            null,
            " Voulez vous vraiment dupliquer la facture d'achat ?"
          ),
        ]),
        showCancelButton: true,
        showClose: false,
        confirmButtonText: "Confirmer",
        cancelButtonText: "Annuler",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "encours...";
            copyBill(e.bill_id, this.listQuery.type)
              .then((res) => {
                setTimeout(() => {
                  setTimeout(() => {
                    instance.confirmButtonLoading = false;
                  }, 300);

                  this.$message({
                    type: "success",
                    message: "La facture d'achat a été dupliquée",
                  });
                  this.$router.push(`/sales/invoice/${res.data._id}`);
                  done();
                }, 1.5 * 1000);
              })
              .catch((err) => {
                setTimeout(() => {
                  this.$message({
                    message: err.data.message,
                    type: "error",
                  });
                  instance.confirmButtonLoading = false;
                  instance.confirmButtonText = "Confirmer";
                }, 1.5 * 1000);
              });
          } else {
            done();
          }
        },
      }).then(() => {});
    },

    handleSuccess(billId) {
      let billItem = this.items.find((el) => el.bill_id === billId);
      billItem.status = "CANCELED";
    },
    handleCommand(e) {
      if (e.get) {
        this.$router.push(`/purchases/bills/${e.get.bill_id}`);
      }
      if (e.cancel) {
        this.handleCanceledBill(e.cancel);
      }

      if (e.update) {
        this.$router.push(`/purchases/bills/update/${e.update.bill_id}`);
      }
      /*
      if (e.copy) {
        this.handleCopyInvoice(e.copy);
      }     if (e.copy) {
        this.handleCopyill(e.copy);
      }
     */
    },
      handleExport(e) {
          if (
              this.listQuery.start === undefined ||
              this.listQuery.end === undefined
          ) {
              this.$message({
                  showClose: true,
                  message: "Veuillez de selectionner l'interval de date.",
                  type: "error",
              });
          } else {
              switch (e) {
                  case "PDF":
                      this.exportBillsListPDF();
                      break;
                  case "EXCEL":
                      this.exportBillsExcel();
                      break;
              }
          }
      },

      exportBillsListPDF() {
          this.exportLoading = true;
          exportBillsPDF(this.listQuery)
              .then((res) => {
                  const url = window.URL.createObjectURL(new Blob([res]));
                  const link = document.createElement("a");
                  link.href = url;
                  link.setAttribute(
                      "download",
                      "facture_achat_" +
                      this.listQuery.start +
                      "-" +
                      this.listQuery.end +
                      this.file
                  ); //or any other extension
                  document.body.appendChild(link);
                  link.click();
                  this.exportLoading = false;
              })
              .catch((err) => {
                  console.log(err);
              });
      },
      exportBillsExcel() {
          this.exportLoading = true;
          exportBillsExcel(this.listQuery)
              .then((res) => {
                  const url = window.URL.createObjectURL(new Blob([res]));
                  const link = document.createElement("a");
                  link.href = url;
                  link.setAttribute("download", "facture_achat" +
                      this.listQuery.start +
                      "-" +
                      this.listQuery.end +".xls"); //or any other extension
                  document.body.appendChild(link);
                  link.click();
                  this.exportLoading = false;
              })
              .catch((err) => {
                  console.log(err);
              });
      },
  },
};
</script>

<style></style>
